<p-growl id="growl" [value]="_messageService.messages" life="3000"></p-growl>

<p-confirmDialog acceptLabel="Sim" width="425" rejectLabel="Não" #cd>
  <footer>
    <button id="nao" type="button" pButton icon="fa-close" label="Não" (click)="cd.reject()"></button>
    <button id="sim" type="button" pButton icon="fa-check" label="Sim" (click)="cd.accept()"></button>
  </footer>
</p-confirmDialog>

<!--

BODY TAG OPTIONS:
=================
Apply one or more of the following classes to get the
desired effect
|---------------------------------------------------------|
| SKINS         | skin-blue (PADRAO)                      |
|               | skin-black (CRIADA)
|               | skin-blue-white (CRIADA)                |
|---------------------------------------------------------|
|LAYOUT OPTIONS | fixed                                   |
|               | layout-boxed                            |
|               | layout-top-nav                          |
|               | sidebar-collapse                        |
|               | sidebar-mini                            |
|---------------------------------------------------------|
-->

<!-- <body class="hold-transition skin-blue sidebar-mini"> -->
<!-- <div class="wrapper" >-->

<!-- Main Header  Cabecaçalho -->
<header class="main-header" *ngIf="authService.isLoggedIn">

  <!-- Logo -->
  <a href="#" class="logo">
    <!-- mini logo for sidebar mini 50x50 pixels -->
    <span class="logo-mini">
      <!--<img class="responsive-img" src="../assets/img/logo/icon/neon-icon-white.png">-->
      <img class="responsive-img" src="../assets/img/logo/icon/logo-icon.png">
      <!--<img class="responsive-img" src="../assets/img/logo/icon/logo-icon-border-7.png">-->
    </span>
    <!-- logo for regular state and mobile devices -->
    <span class="logo-lg">
      <!--<img class="responsive-img" src="../assets/img/logo/pequeno/logo-white.png"> -->
      <img class="responsive-img" src="../assets/img/logo/pequeno/logo.png">
      <!--<img class="responsive-img" src="../assets/img/logo/pequeno/logo-border-15.png">-->
    </span>
  </a>

  <!-- Header Navbar -->
  <nav class="navbar navbar-static-top" role="navigation">
    <!-- Sidebar toggle button-->
    <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
      <span class="sr-only">Toggle navigation</span>
    </a>
    <!-- Navbar Right Menu -->
    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        <li class="dropdown messages-menu">
          <a href="https://api.whatsapp.com/send?phone=556239334300&text=Enviando%20mensagem" target="_blank"
            style="cursor: pointer"><i class="fa fa-whatsapp" style="font-size: 21px;"></i></a>
        </li>
        <!-- Messages: style can be found in dropdown.less-->
        <li class="dropdown messages-menu">
          <!-- Menu toggle button -->
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="fi-dashboard-opening-email"></i>
            <span class="label label-danger">4</span>
          </a>
          <ul class="dropdown-menu">
            <li class="header"> 4 Mensagens </li>
            <li>
              <!-- inner menu: contains the messages -->
              <ul class="menu">
                <li>
                  <!-- start message -->
                  <a href="#">
                    <div class="pull-left">
                      <!-- User Image -->
                      <img src="../assets/img/logo-usuario.png" class="img-circle" alt="User Image">
                    </div>
                    <!-- Message title and timestamp -->
                    <h4>
                      Mensagens
                      <small>
                        <i class="fa fa-clock-o"></i> 5 mins</small>
                    </h4>
                    <!-- The message -->
                    <p> Local Reservado para Alerta. </p>
                  </a>
                </li>
                <!-- end message -->
              </ul>
              <!-- /.menu -->
            </li>
            <li class="footer">
              <a href="#"> Acessar as Mensagens </a>
            </li>
          </ul>
        </li>
        <!-- /.messages-menu -->

        <!-- Notifications Menu -->
        <li class="dropdown notifications-menu">
          <!-- Menu toggle button -->
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="fi-dashboard-bell-outline"></i>
            <span class="label label-danger">10</span>
          </a>
          <ul class="dropdown-menu">
            <li class="header"> Notificações </li>
            <li>
              <!-- Inner Menu: contains the notifications -->
              <ul class="menu">
                <li>
                  <!-- start notification -->
                  <a href="#">
                    <i class="fa fa-users text-aqua"></i>  Financeiro
                  </a>
                </li>
                <!-- end notification -->
              </ul>
            </li>
            <li class="footer">
              <a href="#">Acessar as notificações</a>
            </li>
          </ul>
        </li>

        <!-- User Account Menu -->
        <li class="dropdown user user-menu">
          <!-- Menu Toggle Button -->
          <a href="#" class="dropdown-toggle loggon-user" data-toggle="dropdown">
            <!-- The user image in the navbar-->
            <img src="{{globalsVariablesService.getUsuarioLogado.caminhoImagem}}" class="user-image" alt="User Image">
            <!-- hidden-xs hides the username on small devices so only the image appears. -->
            <span class="hidden-xs"> {{globalsVariablesService.getUsuarioLogado.pessoaNomeFantasia}} </span>
          </a>
          <ul class="dropdown-menu">
            <!-- The user image in the menu -->
            <li class="user-header">
              <img src="{{globalsVariablesService.getUsuarioLogado.caminhoImagem}}" class="img-circle" alt="User Image">
              <p>
                <small>{{globalsVariablesService.getUsuarioLogado.pessoaNomeFantasia}} <br> Usuário desde Nov.
                  2012</small>
              </p>
            </li>
            <!-- Menu Body -->
            <!--
                <li class="user-body">
                  <div class="row">
                    <div class="col-xs-4 text-center">
                      <a href="#">Followers</a>
                    </div>
                    <div class="col-xs-4 text-center">
                      <a href="#">Vendas</a>
                    </div>
                    <div class="col-xs-4 text-center">
                      <a href="#">Usuarios</a>
                    </div>
                  </div>
                </li>.row -->

            <!-- Menu Footer-->
            <li class="user-footer">
              <div class="pull-left">
                <a routerLink="/seguranca/usuario" class="btn btn-default btn-flat">Perfil</a>
              </div>
              <div class="pull-right">
                <a (click)="logout()" class="btn btn-default btn-flat">Logout</a>
              </div>
            </li>
          </ul>
        </li> <!-- Fazer Logout -->
        <li>
          <a (click)="logout()" data-toggle="control-sidebar">
            <i class="fa fa-power-off"></i>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header>
<!-- Left side column. contains the logo and sidebar -->
<aside class="main-sidebar" [ngClass]="{'show':authService.isLoggedIn}">

  <!-- sidebar: style can be found in sidebar.less -->
  <section class="sidebar">

    <!-- Sidebar user panel (optional) -->
    <div class="user-panel">
      <div class="pull-left image">
        <img src="{{globalsVariablesService.getUsuarioLogado.caminhoImagem}}" class="img-circle" alt="User Image">
      </div>
      <div class="pull-left info">
        <p><span>Olá</span>,<br>{{globalsVariablesService.getUsuarioLogado.pessoaNomeFantasia}}</p>
        <!-- Status -->
        <a href="#">
          <i class="fa fa-circle text-success"></i> Online</a>
      </div>
    </div>

    <!-- search form (Optional) -->
    <form action="#" method="get" class="menu-search sidebar-form">
      <div class="input-group">
        <input type="text" name="q" class="form-control" placeholder="Pesquisar...">
        <span class="input-group-btn">
          <button type="submit" name="search" id="search-btn" class="btn btn-flat">
            <i class="fi-interface-search"></i>
          </button>
        </span>
      </div>
    </form>
    <!-- /.search form -->

    <!-- Sidebar Menu -->
    <ul class="sidebar-menu tree" data-widget="tree">

      <li class="treeview" class="menuSelecionado">
        <a class="rota" routerLink="/dashboard">
          <i class="rota fi-dashboard-graphic"></i>
          <span class="rota"> Dashboard </span>
        </a>
      </li>

      <li class="treeview">
        <a>
          <i class="fi-dashboard-dashboard"></i>
          <span>Membresia</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarIgreja" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/igreja">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Igreja Local </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFaixaEtaria" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/faixa-etaria">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Faixa Etária </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarProfissao" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/profissao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Profissão </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarMinisterio" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/ministerio">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Ministério </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarGrupo" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/grupo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Grupo </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRede" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/rede">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Rede </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarDiscipulado" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/discipulado">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Discipulado </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCelula" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/celula">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Célula </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFuncaoCelula" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/funcao-celula">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Funções na Célula </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCargo" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/cargo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Cargo </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarMembro" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/membro">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Membro </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarOcorrencia" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/ocorrencia">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Ocorrência </span>
            </a>
          </li>

        </ul>
      </li>

      <li class="treeview">
        <a>
          <i class="fi-ecommerce-money"></i>
          <span>Financeiro</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">

         <li *ngIf="globalsVariablesService.getPermissoes.visualizarFornecedor" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/fornecedor">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Fornecedor </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFormaPagamento" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/forma-pagamento">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Forma de Pagamento </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPrazo" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/prazo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Prazo </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFormaRecebimento" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/pdv-pagamento">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Forma de Recebimento </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarBanco" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/banco">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Banco </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPlanoContas" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/plano-contas">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Plano de Contas </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarContaContabil" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/conta-contabil">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Conta Contábil </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCentroCustos" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/centro-custos">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Centro Custos </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarConfiguracaoContabil" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/configuracao-contabil">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Configuração Contábil </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarContaLancamento" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/conta">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Conta de Lançamento </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarDuplicataReceber" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/duplicata-receber">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Duplicatas a Receber </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarBaixaParcelaReceber" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/baixa-parcela-receber">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Baixa Parcela Receber </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarDuplicataPagar " class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/duplicata-pagar">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Duplicatas a Pagar </span>
            </a>
          </li>
          <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarBaixaParcelaPagar" -->

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarBaixaParcelaPagar" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/baixa-parcela-pagar">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Baixa Parcela Pagar </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarDizimos" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/dizimo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Dizimos </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarLancamentoFinanceiro" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/lancamentos-financeiros">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Lançamentos Financeiros </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarEstornoDizimos"
            class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/estorno-dizimo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Estorno Dízimo </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTransferenciaContas" >
            <a class="rota" routerLink="/financeiro/transferencia-contas" class="menuSelecionado">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Transferência Contas </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFechamentoContas" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/fechamento-conta">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Fechamento da Conta </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFechamentoPlanoContas" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/fechamento-plano-conta">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Fechamento Plano Conta </span>
            </a>
          </li>

        </ul>
      </li>

      <li class="treeview">
        <a>
          <i class="fi-ecommerce-padlock"></i>
          <span>Segurança</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarGrupoLoja" class="menuSelecionado">
            <a class="rota" routerLink="/seguranca/grupo-igreja">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Grupo Igreja </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPermissao" class="menuSelecionado">
            <a class="rota" routerLink="/seguranca/permissao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Permissões </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarUsuario" class="menuSelecionado">
            <a class="rota" routerLink="/seguranca/usuario">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Usuário </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTrocarSenha" class="menuSelecionado">
            <a class="rota" routerLink="/seguranca/trocar-senha">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Trocar Senha </span>
            </a>
          </li>

        </ul>
      </li>

      <li class="treeview">
        <a>
          <i class="fi-dashboard-rounded-paper-with-text-lines-and-one-folded-corner"></i>
          <span>Relatórios</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioMembro" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-membros">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Membros </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioFichaMembro" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-ficha-membro">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Ficha de membro </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioAniversariante" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-aniversariante">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Aniversariantes </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioCarteirinhas" class="menuSelecionado">
          <a class="rota" routerLink="/relatorios/rel-carteira-membro">
            <span class="rota icon">
              <i class="rota fa fa-circle-o"></i> Carteira Membros </span>
          </a>
        </li>
        <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioCertificado" class="menuSelecionado">
          <a class="rota" routerLink="/relatorios/rel-certificado-membro">
            <span class="rota icon">
              <i class="rota fa fa-circle-o"></i> Certificado Membros </span>
          </a>
        </li>
         <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioPlanoContas" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-plano-contas">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Plano Contas </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioDuplicatasReceber"
            class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-duplicata-receber">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Duplicatas a Receber </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioDuplicatasPagar" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-duplicata-pagar">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Duplicatas a Pagar </span>
            </a>
          </li>
         <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioLancamentoFinanceiro" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-lanc-financeiro">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Lançamentos Financeiro </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioDizimos" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-dizimos">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Dizimos </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioMovimentacaoPlanoContas" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-movimentacao-plano-contas">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Movimentação Plano de Contas</span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioFechamentoPlanoContas" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-fechamento-plano-contas">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Fechamento Plano de Contas</span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarGerarArquivo" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/gerar-arquivo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Gerar Arquivo Contabilidade</span>
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview">
        <a>
          <i class="fi-creative-settings-2"></i>
          <span>Configurações</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarImportacaoXLS" class="menuSelecionado">
            <a routerLink="/configuracoes/importacao-xls">
              <span class="icon">
                <i class="fa fa-circle-o"></i> Importação XLS</span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSetup" class="menuSelecionado">
            <a class="rota" routerLink="/configuracoes/setup">
              <span class="rota icon">
                <i class="rota rota fa fa-circle-o"></i> Setup </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarModeloDocumentos" class="menuSelecionado">
            <a class="rota" routerLink="/configuracoes/modeloDocumentos">
              <span class="rota icon">
                <i class="rota rota fa fa-circle-o"></i> Modelo Documentos </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTipoRecebimento" class="menuSelecionado">
            <a class="rota" routerLink="/configuracoes/tipo-recebimento">
              <span class="rota icon">
                <i class="rota rota fa fa-circle-o"></i> Tipo Recebimento </span>
            </a>
          </li>
          
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarConfiguracaoRecebimento" class="menuSelecionado">
            <a class="rota" routerLink="/configuracoes/configuracao-recebimento">
              <span class="rota icon">
                <i class="rota rota fa fa-circle-o"></i> Conf. Recebimento </span>
            </a>
          </li>

          <li  class="menuSelecionado">
            <a class="rota" routerLink="/configuracoes/conf-documentos">
              <span class="rota icon">
                <i class="rota rota fa fa-circle-o"></i> Conf. de Documentos </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCredenciadoraCartao" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/credenciadora-cartao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Credenciadora Cartão </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarBandeiraCartao" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/bandeira-cartao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Bandeira Cartão </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTipoWeb" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/tipo-web">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Tipo WEB </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTipoTelefone" class="menuSelecionado">
            <a class="rota" routerLink="/membresia/tipo-telefone">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Tipo Telefone </span>
            </a>
          </li>

        </ul>
      </li>

    </ul>
    <!-- /.sidebar-menu -->
  </section>
  <!-- /.sidebar -->
</aside>

<!-- Content Wrapper. Contains page content -->
<div [ngClass]="{'content-wrapper': authService.isLoggedIn}">
  <!-- Content Header (Page header) -->
  <!--
          <section class="content-header">
            <h1>
              Page Header
              <small>Optional description</small>
            </h1>
            <ol class="breadcrumb">
              <li><a href="#"><i class="fa fa-dashboard"></i> Level</a></li>
              <li class="active">Here</li>
            </ol>
          </section> -->

  <!-- Main content -->
  <section class="content">

    <router-outlet>

    </router-outlet>

  </section>
  <!-- /.content -->
</div>
<!-- /.content-wrapper -->

<!-- Main Footer -
    <footer class="main-footer" *ngIf="authService.isLoggedIn" >

      <div class="pull-right hidden-xs">
        ..
      </div>
      <!-- Default to the left
      <strong>Copyright &copy; 2016 <a href="#">Araguaia Sistemas</a>.</strong>todos os direitos reservados.

    </footer>  -->

<!-- /.control-sidebar -->
<!-- Add the sidebar's background. This div must be placed
       immediately after the control sidebar -->

<div class="control-sidebar-bg" *ngIf="authService.isLoggedIn"></div>

<!-- </div> ./wrapper -->

<!-- REQUIRED JS SCRIPTS -->

<!-- jQuery 2.2.0 -->
<!-- Optionally, you can add Slimscroll and FastClick plugins.
     Both of these plugins are recommended to enhance the
     user experience. Slimscroll is required when using the
     fixed layout. -->
<!--</body> -->
